<template>
  <article class="module">
    <div class="container" v-if="isRzecommerce2024Event">
      <rzecommerce-speakers-component />
    </div>
    <div v-else class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div v-if="isLoading">
        <list-loading-indicator :active="true"></list-loading-indicator>
      </div>
      <div v-else>
        <search-input v-if="!searchDisabled" class="mb-4" :placeholder="$t('hints.search_hint_speakers')" v-model="search"></search-input>
        <speakers-pager-view :speakers="speakers" :show-country="showCountry" :lp-mode="true"
                             :page-size="pageSize"></speakers-pager-view>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import Separator from "@/web/components/shared/Separator";
import SearchInput from "@/shared/components/SearchInput";
import SpeakersPagerView from "@/shared/components/speakers/SpeakersPagerView";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import RzecommerceSpeakersComponent from "@/web/components/custom/rzecommerce/RzecommerceSpeakersComponent";

export default {
  name: "SpeakersComponent",
  mixins: [LpConfigMixin],
  components: {
    Separator,
    SearchInput,
    SpeakersPagerView,
    ListLoadingIndicator,
    RzecommerceSpeakersComponent,
  },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      search: "",
    };
  },

  methods: {
    ...mapActions("speakers", ["fetch"]),
    ...mapMutations("modals", ["closeSpeakerDetailsModal"]),
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("components", ["getComponentById"]),
    ...mapGetters("speakers", ["searchFromComponent", "isLoading"]),

    speakersModuleFields: () => LpConfigConstants.SPEAKERS_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    isRzecommerce2024Event() {
      return this.eventId === 1643;
    },

    speakers() {
      return this.searchFromComponent(this.componentId, this.search);
    },

    componentId() {
      return this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.COMPONENT_ID, null);
    },

    component() {
      return this.getComponentById(this.componentId);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.speakersModuleFields.PAGE_SIZE, 6);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE, this.component.label);
    },

    showCountry() {
      return this.getFieldValueOrDefault(this.moduleData, this.speakersModuleFields.SHOW_COUNTRY, false);
    },

    searchDisabled() {
      return this.isRzecommerce2024Event || this.getFieldValueOrDefault(this.moduleData, this.speakersModuleFields.SEARCH_DISABLED, false);
    }
  },
};
</script>
