import Constants from "@/web/constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

import MobileAppInfoComponent from "@/web/components/mobileapp/MobileAppInfoComponent";
import IFrameComponent from "@/web/components/IFrameComponent";
import IFrameNativeComponent from "@/web/components/IFrameNativeComponent";
import SocialLinksComponent from "@/web/components/SocialLinksComponent";
import LiveWebinarComponent from "@/web/components/LiveWebinarComponent";
import AgendaComponent from "@/web/components/AgendaComponent";
import ExhibitorsComponent from "@/web/components/ExhibitorsComponent";
import EventDescriptionComponent from "@/web/components/EventDescriptionComponent";
import PartnersSmartView from "@/web/components/PartnersComponent";
import EventTicketsComponent from "@/web/components/EventTicketsComponent";
import SpeakersComponent from "@/web/components/SpeakersComponent";
import AttendeesComponent from "@/web/components/AttendeesComponent";
import QuizzesComponent from "@/web/components/QuizzesComponent";
import TwitterComponent from "@/web/components/TwitterComponent";
import CustomerSupportComponent from "@/web/components/CustomerSupportComponent";
import PismAboutCustomComponent from "@/web/components/custom/PismAboutCustomComponent";
import PismTopicsCustomComponent from "@/web/components/custom/PismTopicsCustomComponent";
import SrkCustomComponent from "@/web/components/custom/survivalracekids/SrkCustomModule";
import ButtonPopupComponent from "@/web/components/ButtonPopupComponent";
import UnityAboutCustomComponent from "@/web/components/custom/UnityAboutCustomComponent";
import UnityTopicsCustomComponent from "@/web/components/custom/UnityTopicsCustomComponent";
import EventTimerComponent from "@/web/components/EventTimerComponent";
import FaqComponent from "@/web/components/FaqComponent";
import MRHCustomComponent from "@/web/components/custom/MRHCustomComponent";
import EventContactInfoComponent from "@/web/components/EventContactInfoComponent";
import Pism2023AboutCustomComponent from "@/web/components/custom/Pism2023AboutCustomComponent";
import Pism2023TopicsCustomComponent from "@/web/components/custom/Pism2023TopicsCustomComponent";
import SpreadsheetFormComponent from "@/web/components/custom/SpreadsheetFormComponent";
import PedTicketComponent from "@/web/components/custom/ped/PedTicketComponent";
import PedInfoComponent from "@/web/components/custom/ped/PedInfoComponent";
import RzecommerceInfoComponent from "@/web/components/custom/rzecommerce/RzecommerceInfoComponent";
import RzecommerceFaqComponent from "@/web/components/custom/rzecommerce/RzecommerceFaqComponent";
import PedStageComponent from "@/web/components/custom/ped/PedStageComponent";
import PedLocationComponent from "@/web/components/custom/ped/PedLocationComponent";
import PedPreviousEditionComponent from "@/web/components/custom/ped/PedPreviousEditionComponent";
import Pism2024PresidentInfoBanner from '@/web/components/custom/Pism2024PresidentInfoBanner';

export default {
  //TODO: Move constants to lp-config-constants
  [Constants.MODULE_MOBILE_APP_INFO]: MobileAppInfoComponent,
  [Constants.MODULE_IFRAME]: IFrameComponent,
  [Constants.MODULE_IFRAME_NATIVE]: IFrameNativeComponent,
  [Constants.MODULE_SOCIAL_LINKS]: SocialLinksComponent,
  [Constants.MODULE_COMPONENT_AGENDA]: AgendaComponent,
  [Constants.MODULE_COMPONENT_EXHIBITORS]: ExhibitorsComponent,
  [Constants.MODULE_COMPONENT_PARTNERS]: PartnersSmartView,
  [Constants.MODULE_COMPONENT_SPEAKERS]: SpeakersComponent,
  [Constants.MODULE_QUIZZES]: QuizzesComponent,
  [Constants.MODULE_COMPONENT_ATTENDEES]: AttendeesComponent,
  [Constants.MODULE_COMPONENT_LIVE_WEBINAR]: LiveWebinarComponent,
  [Constants.MODULE_EVENT_DESCRIPTION]: EventDescriptionComponent,
  [Constants.MODULE_EVENT_TICKETS]: EventTicketsComponent,
  [Constants.MODULE_TWITTER]: TwitterComponent,
  [Constants.MODULE_CUSTOMER_SUPPORT]: CustomerSupportComponent,
  [Constants.MODULE_BUTTON_POPUP]: ButtonPopupComponent,
  [Constants.MODULE_EVENT_TIMER]: EventTimerComponent,
  [Constants.MODULE_FAQ]: FaqComponent,
  [Constants.MODULE_EVENT_CONTACT_INFO]: EventContactInfoComponent,
  "pism_module_1": PismAboutCustomComponent,
  "pism_module_2": PismTopicsCustomComponent,
  "pism_2023_module_1": Pism2023AboutCustomComponent,
  "pism_2023_module_2": Pism2023TopicsCustomComponent,
  "unity_module_1": UnityAboutCustomComponent,
  "unity_module_2": UnityTopicsCustomComponent,
  "srk_module": SrkCustomComponent,
  "mrh_module": MRHCustomComponent,
  "spreadsheet_form_module": SpreadsheetFormComponent,
  [LpConfigConstants.MODULE_TYPES.PED_TICKET]: PedTicketComponent,
  [LpConfigConstants.MODULE_TYPES.PED_INFO]: PedInfoComponent,
  [LpConfigConstants.MODULE_TYPES.PED_STAGE]: PedStageComponent,
  [LpConfigConstants.MODULE_TYPES.PED_LOCATION]: PedLocationComponent,
  [LpConfigConstants.MODULE_TYPES.PISM_2024_PRESIDENT_BANNER]: Pism2024PresidentInfoBanner,
  [LpConfigConstants.MODULE_TYPES.PED_PREVIOUS_EDITION]: PedPreviousEditionComponent,
  [LpConfigConstants.MODULE_TYPES.RZECOMMERCE_INFO]: RzecommerceInfoComponent,
  [LpConfigConstants.MODULE_TYPES.RZECOMMERCE_FAQ]: RzecommerceFaqComponent,
};
