<template>
  <div class="columns is-desktop">
    <div class="column">
      <purchase-call-to-action
        v-if="eventId === 1251 && callToActionVisible && step === 0 && !isAuthorized"
        :authorize-visible="false"
        :payment-config="paymentConfig"
      >
      </purchase-call-to-action>
      <purchase-call-to-action
        v-if="callToActionVisible"
        :authorize-visible="authorizeCallToActionVisible"
        :payment-config="paymentConfig"
      >
      </purchase-call-to-action>
      <slot></slot>
    </div>
    <div class="column is-narrow summary-container">
      <h3 class="title is-3 mb-3">{{ $t("payment.summary") }}</h3>
      <purchase-summary :step="step"></purchase-summary>
      <div class="buttons">
        <slot name="buttons"></slot>
      </div>
      <div class="errors">
        <slot name="errors"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseSummary from "@/web/components/purchase/PurchaseSummary";
import { mapGetters, mapState } from "vuex";
import PurchaseCallToAction from "@/web/components/purchase/PurchaseCallToAction";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { hasDisabledAuth } from "@/web/store/getters";

export default {
  name: "PurchaseStep",

  props: {
    step: {
      type: Number,
      required: true,
    },
  },

  components: {
    PurchaseCallToAction,
    PurchaseSummary,
  },

  computed: {
    ...mapGetters(["paymentConfig", "hasDisabledAuth"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapState(["eventId"]),

    paymentFields: () => LpConfigConstants.PAYMENT_FIELDS,

    authorizeCallToActionVisible() {
      return !this.isAuthorized && this.step === 0 && !this.hasDisabledAuth;
    },

    authorizeCustomCallToActionVisible() {
      const callToActionKeys = [
        this.paymentFields.CALL_TO_ACTION_IMAGE_URL_KEY,
        this.paymentFields.CALL_TO_ACTION_TITLE_KEY,
        this.paymentFields.CALL_TO_ACTION_DESCRIPTION_KEY,
      ];
      return !!Object.entries(this.paymentConfig).filter(
        (keyValue) => callToActionKeys.includes(keyValue[0]) && !!keyValue[1]
      ).length;
    },

    callToActionVisible() {
      return this.authorizeCallToActionVisible || this.authorizeCustomCallToActionVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.errors {
  word-break: break-word;
  max-width: 310px;
}

.buttons {
  flex-wrap: nowrap;
}

.summary-container {
  min-width: 320px;
}
</style>
