<template>
  <div class="rzecommerce-speakers">
    <div class="recommerce-speakers__header">
      <h1 class="rzecommerce-title mb-4">
        {{ $t("custom.rzecommerce_speakers_title") }}
      </h1>
    </div>
    <div v-if="isLoading">
      <list-loading-indicator :active="true"></list-loading-indicator>
    </div>
    <div v-else class="rzecommerce-speakers__list">
      <div class="rzecommerce-speakers__item" v-for="(speaker, index) in speakersToDisplay" :key="index" @click="openSpeakerInfo(speaker)">
        <img v-if="speakerImgUrl(speaker)" :src="speakerImgUrl(speaker)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "RzecommerceSpeakersComponent",
  mixins: [LpConfigMixin],
  components: {
    ListLoadingIndicator,
  },

  data() {
    return {
      componentId: 18926,
    };
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("speakers", ["searchFromComponent", "isLoading"]),

    speakers() {
      return this.searchFromComponent(this.componentId, "");
    },

    speakersToDisplay() {
      return this.speakers && this.speakers.filter(s => this.speakerImgUrl(s) !== null) || [];
    },
  },

  methods: {
    speakerImgUrl(speaker) {
      return (speaker && speaker.picture && speaker.picture.file_url) || null;
    },

    openSpeakerInfo(speaker) {
      this.$root.openSpeakerModal({
        speaker: speaker,
        eventId: this.eventId,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.rzecommerce-speakers__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.rzecommerce-speakers__item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

@media screen and (max-width: 1024px) {
  .rzecommerce-speakers__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .rzecommerce-speakers__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .rzecommerce-speakers__list {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }

  .rzecommerce-speakers__item {
    margin: 0 auto;
    max-width: 80%;
  }
}

.rzecommerce-title {
  text-align: end;
}
</style>
